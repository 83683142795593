import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { CustomLinkComponent, Icons, Image } from 'msp-components'
import { productUrlBuilder } from 'msp-integrations'
import useProduct from '@/hooks/use-product'
import { AmountAttributeIndicator } from '@/components/molecules/AmountAttributeIndicator'
import { CoffeeCategoryTileProps } from './CoffeeCategoryTile.types'

const CoffeeCategoryTile = ({
  productId,
  imageAlt,
  tileImage,
  className = '',
  fontColor,
  setGlobalTileCSSClass
}: CoffeeCategoryTileProps) => {
  const { formatMessage } = useIntl()

  const [isMouseHovered, setIsMouseHovered] = useState(false)

  const {
    showLoading,
    c_flavorIntensity,
    c_flavour,
    c_brew_time,
    c_brew_temp,
    name,
    c_primaryCategoryName,
    c_primaryCategoryPath,
    c_classCategoryName,
    c_classCategoryPath
  } = useProduct(productId)

  const attributes = [
    {
      name: formatMessage({
        id: 'msp.product.attributes.intensity',
        defaultMessage: 'Intensity'
      }),
      attributeIndicator: true,
      value: c_flavorIntensity || 0
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.flavor',
        defaultMessage: 'Flavor'
      }),
      attributeIndicator: false,
      value: c_flavour && (
        <div className={'text-12 font-Roboto font-normal'}>
          {` ${c_flavour?.slice(0, 2).join(', ')}`}
        </div>
      )
    },
    {
      name: formatMessage({
        id: 'msp.product.attributes.brew_time',
        defaultMessage: 'Brew time'
      }),
      attributeIndicator: false,
      value: c_brew_time && (
        <div
          className={'text-12 font-Roboto font-normal'}
        >{` ${c_brew_time} Min / ${c_brew_temp} °C`}</div>
      )
    }
  ]

  const handleMouseOver = () => {
    if (setGlobalTileCSSClass) setGlobalTileCSSClass('cc-blur-tile')
    setIsMouseHovered(true)
  }

  const handleMouseLeave = () => {
    if (setGlobalTileCSSClass) setGlobalTileCSSClass('')
    setIsMouseHovered(false)
  }

  return (
    <div
      className={`cc-tile-container ${className} ${
        isMouseHovered ? 'cc-unblur-tile' : ''
      }`}
    >
      <div className='cc-tile-image-link-wrapper'>
        <div
          className='cc-tile-image-wrapper'
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {!showLoading && (
            <CustomLinkComponent
              href={productUrlBuilder({
                name: name || '',
                id: productId,
                classCategoryName: c_classCategoryName,
                classCategoryPath: c_classCategoryPath,
                primaryCategoryName: c_primaryCategoryName,
                primaryCategoryPath: c_primaryCategoryPath
              })}
              colorScheme='whiteAlpha'
              className='cc-tile-custom-link'
            >
              <Image
                src={tileImage}
                className='cc-tile-image'
                width='160'
                height='160'
                forceScale
                alt={imageAlt || name}
                ratio='1/4'
              />
              <div
                className={`cc-tile-link-wrapper ${
                  isMouseHovered ? 'cc-tile-link-wrapper-hover' : ''
                } `}
              >
                <div>
                  <div
                    className={`cc-tile-plus-icon ${
                      isMouseHovered ? 'cc-tile-plus-icon-hover' : ''
                    }`}
                  >
                    <Icons iconName='Close' height='10px' width='10px' />
                  </div>
                </div>
              </div>
            </CustomLinkComponent>
          )}
        </div>
      </div>
      <div
        className='cc-tile-attributes-main text-white'
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {attributes?.length > 0 &&
          attributes.map(({ name, attributeIndicator, value }, idx) => (
            <div
              key={`cc-tile-attrib-${idx}`}
              className='cc-tile-attributes-wrapper'
            >
              <div className={`cc-tile-prod-attributes text-${fontColor}`}>
                {`${name}: `}
              </div>
              {attributeIndicator ? (
                <AmountAttributeIndicator
                  className={`cc-tile-attributes-amount-indicator fill-${fontColor} stroke-${fontColor}`}
                  quantity={value}
                  fillColor={fontColor}
                  height='10px'
                />
              ) : (
                <div className={`text-${fontColor}`}>{value}</div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default CoffeeCategoryTile
